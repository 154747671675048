import axios from 'axios';
import { useState, useEffect, useMemo } from 'react';
import { exampleJourneys, useIsJourneyCompleted } from '../components/JourneyComponent.tsx';

interface BadgeWithoutSortScore {
  id: string;
  name: string;
  description: string;
  rarityName: 'common' | 'rare' | 'legendary';
  rarityScore: 1 | 2 | 4;
  rarityImageUrl: string;
  imageUrl: string;
  smallImageUrl: string;
  credits: string;
}

export interface Badge extends BadgeWithoutSortScore {
  sortScore: number; // Sort score is the total amount of this claimed / Rarity Score
  participantIDsWhoOwnThisBadge: string[]; // Profile emails
}

interface ProfileFoundation {
  email: string;
  name: string;
  lastUpdated: Date;
}

interface ProfileWithoutSortScoreAndBadgeIDs extends ProfileFoundation {
  badgesNames: string[];
}

interface ProfileWithoutSortScore extends ProfileFoundation {
  badgesIDs: string[];
}

export interface Profile extends ProfileWithoutSortScore {
  sortScore: number; // Profile Score is the sum of the rarity scores of the badges they own
  completedJourneys?: string[];
}

export const useBadgeWithoutSortScore = () => {
  const allBadgesUrl =
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vQZQnUd9zTEoxUtGuUJEXWwgdPAX67HPWGuHH_waJj6z_9P4qbYIVlkfV3Nmw9GiGZ-2nbOrnMRTQ6y/pub?gid=0&single=true&output=csv';
  const [badgeWithoutSortScoreMap, setBadgeWithoutSortScoreMap] = useState<Map<string, BadgeWithoutSortScore>>(
    new Map()
  );
  useEffect(() => {
    async function fetchAllBadges(): Promise<Map<string, BadgeWithoutSortScore>> {
      // cancel all axios requests
      const response = await axios.get(allBadgesUrl);
      const badgesMap = new Map<string, BadgeWithoutSortScore>();
      response.data.split('\n').forEach((line) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [name, description, rarity, status, credits, message, id] = line
          .split(',')
          .map((item: string) => item.trim()) as string[];
        const badgeImageName = name.replace(/\s+/g, '_') + '.png';
        badgesMap.set(id, {
          id,
          name,
          description,
          rarityName: rarity as 'common' | 'rare' | 'legendary',
          rarityScore: rarity === 'common' ? 1 : rarity === 'rare' ? 2 : 4,
          imageUrl: `${process.env.PUBLIC_URL}/images/${badgeImageName}`,
          smallImageUrl: `${process.env.PUBLIC_URL}/images/resized-images/${badgeImageName}`,
          credits: credits || 'Unknown credits',
          rarityImageUrl: `${process.env.PUBLIC_URL}/images/${rarity}.png`,
        });
      });
      return badgesMap;
    }

    fetchAllBadges().then(setBadgeWithoutSortScoreMap);
  }, []);

  return { badgeWithoutSortScoreMap };
};

const useProfileWithoutSortScoreAndBadgeIDs = () => {
  const responsesUrl =
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vSVk_rVJyxSzIVAL0Mw3z-qEwjbMXWoP5oQh_CvYNipU-EiTCqg8uxZZJAgG7ljxzFsLR_7Z69cWknB/pub?gid=1669489493&single=true&output=csv';

  // fetch all the profiles from the google sheet
  const [profileFoundationsMap, setProfileFoundationsMap] = useState<Map<string, ProfileWithoutSortScoreAndBadgeIDs>>(
    new Map()
  );
  useEffect(() => {
    async function fetchAllProfiles(): Promise<Map<string, ProfileWithoutSortScoreAndBadgeIDs>> {
      const response: { data: string } = await axios.get(responsesUrl);
      const pMap = new Map<string, ProfileWithoutSortScoreAndBadgeIDs>();

      let lines = response.data.split('\n');
      lines.shift();

      lines.forEach((line) => {
        const [timestamp, email, name] = line.trim().split(',');
        const badgesNames = line
          .split(',')
          .map((item) => item.trim())
          .filter((item) => item !== '')
          .map((item) => item.replace(`"`, '').split(' - ')[0]);

        pMap.set(email, {
          email,
          badgesNames,
          name: name || email?.split('@')[0],
          lastUpdated: new Date(new Date(timestamp).getTime() - new Date(0).getTimezoneOffset() * 60000),
        });
      });

      return pMap;
    }
    fetchAllProfiles().then(setProfileFoundationsMap);
  }, []);

  return { profileFoundationsMap };
};

export const useProfilesWithoutSortScore = (badgesMap: Map<string, BadgeWithoutSortScore>) => {
  const { profileFoundationsMap } = useProfileWithoutSortScoreAndBadgeIDs();
  const [profilesWithoutSortScoreMap, setProfilesWithoutSortScoreMap] = useState<Map<string, ProfileWithoutSortScore>>(
    new Map()
  );

  const createProfilesWithoutSortScore = (
    badgesMap: Map<string, BadgeWithoutSortScore>,
    profileFoundationsMap: Map<string, ProfileWithoutSortScoreAndBadgeIDs>
  ): Map<string, ProfileWithoutSortScore> => {
    const profilesMap = new Map<string, ProfileWithoutSortScore>();
    profileFoundationsMap.forEach((profileFoundation) => {
      const profileBadges = profileFoundation.badgesNames
        .map((badgeName) => Array.from(badgesMap.values()).find((badge) => badge.name === badgeName))
        .filter(Boolean) as BadgeWithoutSortScore[];
      profilesMap.set(profileFoundation.email, {
        email: profileFoundation.email,
        badgesIDs: profileBadges.map((badge) => badge.id),
        name: profileFoundation.name,
        lastUpdated: profileFoundation.lastUpdated,
      });
    });
    return profilesMap;
  };

  useEffect(() => {
    if (badgesMap.size > 0 && profileFoundationsMap.size > 0) {
      setProfilesWithoutSortScoreMap(createProfilesWithoutSortScore(badgesMap, profileFoundationsMap));
    }
  }, [badgesMap, profileFoundationsMap]);

  return { profilesWithoutSortScoreMap };
};

export const useBadges = (
  profilesMap: Map<string, Profile>,
  badgeWithoutSortScoreMap: Map<string, BadgeWithoutSortScore>
) => {
  const [badgesMap, setBadgesMap] = useState<Map<string, Badge>>(new Map());

  useEffect(() => {
    if (badgeWithoutSortScoreMap.size > 0) {
      const badges = Array.from(badgeWithoutSortScoreMap.values()).map((badgeWithoutSortScore) => {
        const participantsWhoOwnThisBadge = Array.from(profilesMap.values()).filter((profile) =>
          profile.badgesIDs.includes(badgeWithoutSortScore.id)
        );
        const sortScorePenalty = 4 - badgeWithoutSortScore.rarityScore;
        return {
          ...badgeWithoutSortScore,
          participantIDsWhoOwnThisBadge: participantsWhoOwnThisBadge.map((participant) => participant.email),
          sortScore: (participantsWhoOwnThisBadge.length + sortScorePenalty) / badgeWithoutSortScore.rarityScore,
        };
      });
      setBadgesMap(new Map(badges.map((badge) => [badge.id, badge])));
    }
  }, [badgeWithoutSortScoreMap, profilesMap]);

  return { badgesMap };
};

export const useProfiles = (
  badgesMap: Map<string, BadgeWithoutSortScore>,
  profilesWithoutSortScoreMap: Map<string, ProfileWithoutSortScore>
) => {
  const [profilesMap, setProfilesMap] = useState<Map<string, Profile>>(new Map());

  const journeyPointsByProfileName: Map<string, number> = useMemo(() => {
    const journeyPointsByProfileName = new Map<string, number>();
    profilesWithoutSortScoreMap.forEach((profile) => {
      const journeysCompletedIndexes: boolean[] = exampleJourneys
        // eslint-disable-next-line react-hooks/rules-of-hooks
        .map((journey) => useIsJourneyCompleted(journey, profile?.badgesIDs ?? []));

      // journeyPointsByProfileName value will be based on each journeys rarityName. common is 1, rare is 2, legendary is 4
      // length of journeysCompletedIndexes will be same as length of exampleJourneys which is Journey[]
      journeyPointsByProfileName.set(
        profile.email,
        journeysCompletedIndexes.reduce((acc, isCompleted, index) => {
          const rarityName = exampleJourneys[index].rarityName;
          return acc + (isCompleted ? (rarityName === 'common' ? 1 : rarityName === 'rare' ? 2 : 4) : 0);
        }, 0)
      );
    });
    return journeyPointsByProfileName;
  }, [profilesWithoutSortScoreMap]);

  useEffect(() => {
    if (profilesWithoutSortScoreMap.size > 0 && journeyPointsByProfileName.size > 0 && badgesMap.size > 0) {
      const profiles: Profile[] = Array.from(profilesWithoutSortScoreMap.values()).map((profileWithoutSortScore) => {
        const badgesTotalSortScore = profileWithoutSortScore.badgesIDs.reduce(
          (acc, badgeID) => acc + (badgesMap.get(badgeID)?.rarityScore || 0),
          0
        );

        return {
          ...profileWithoutSortScore,
          sortScore: badgesTotalSortScore + journeyPointsByProfileName.get(profileWithoutSortScore.email)!,
        };
      });
      setProfilesMap(
        new Map(profiles.sort((a, b) => b.sortScore - a.sortScore).map((profile) => [profile.email, profile]))
      );
    }
  }, [profilesWithoutSortScoreMap, badgesMap, journeyPointsByProfileName]);

  return { profilesMap };
};
