import React, { SyntheticEvent, useState } from 'react';
import type { Badge } from '../hooks/useTalosBadges';
import './BadgeComponent.css';

const placeholderUrl = 'https://via.placeholder.com/256';

interface BadgeComponentProps {
  badge: Badge;
  onClick: (badge: Badge) => void;
  highlight?: boolean | null;
  display?: boolean;
  showName?: boolean;
  showDescription?: boolean;
  useDelay?: boolean;
  enableInteraction?: boolean;
  blackAndWhite?: boolean;
  smaller?: boolean;
}

export const BadgeComponent = ({
  badge,
  onClick,
  highlight,
  display,
  enableInteraction = true,
  showName = enableInteraction,
  showDescription = enableInteraction,
  blackAndWhite = !enableInteraction,
  useDelay = false,
  smaller,
}: BadgeComponentProps) => {
  const [hovered, setHovered] = useState(false);
  const delayString = useDelay ? `${Math.floor(Math.random() * 500) + 500}ms` : `0ms`;

  return (
    <div
      className={`badge rarity-${badge.rarityName} fadeIn ${smaller ? 'smaller border-radius' : ''}`}
      style={{ '--delay': delayString } as React.CSSProperties}
      onClick={() => enableInteraction && onClick(badge)}
      onMouseEnter={() => {
        if (showDescription && enableInteraction) {
          setHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (showDescription) {
          setHovered(false);
        }
      }}
    >
      <div className="badge-inner">
        {enableInteraction && (
          <img
            className={`badge-rarity glow-effect-${badge.rarityName}`}
            src={badge.rarityImageUrl}
            alt={badge.rarityName}
          />
        )}
        <img
          className={`badge-image 
          ${smaller ? 'smaller' : ''}
          ${!enableInteraction && 'disable-interaction'} 
          ${highlight && 'highlight'} 
          ${blackAndWhite && 'black-and-white'}
          ${blackAndWhite && !enableInteraction && 'opacity-transition'}
          ${display && 'display'}`}
          src={badge.smallImageUrl}
          onError={(e: SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = placeholderUrl)}
          alt={badge.name}
        />
      </div>
      {hovered && showDescription && (
        <div className="floating-description">
          <p>{badge.description}</p>
        </div>
      )}
      {showName && <p className="floating-name">{badge.name}</p>}
    </div>
  );
};
