import React, { useMemo, useState } from 'react';
import './CollectedBadgesContainer.css';
import type { Badge, Profile } from '../hooks/useTalosBadges';
import { BadgeComponent } from '../components/BadgeComponent.tsx';

// const commonURL = `${process.env.PUBLIC_URL}/images/common.png`;
// const rareURL = `${process.env.PUBLIC_URL}/images/rare.png`;
// const legendaryURL = `${process.env.PUBLIC_URL}/images/legendary.png`;

interface CollectedBadgesContainerProps {
  badgesMap: Map<string, Badge>;
  badgeIDs: string[];
  onClickBadge: (badge: Badge) => void;
  selectedProfile: Profile | null;
  viewNextBadges: boolean;
  allowFilters?: boolean;
  clearResultsOnFilter?: boolean;
}

export const CollectedBadgesContainer = ({
  badgesMap,
  badgeIDs,
  onClickBadge,
  selectedProfile,
  viewNextBadges,
  allowFilters = false,
  clearResultsOnFilter = true,
}: CollectedBadgesContainerProps) => {
  const sortedBadgeIDS = useMemo(
    () =>
      badgeIDs.sort((a, b) => {
        const badgeA = badgesMap.get(a);
        const badgeB = badgesMap.get(b);
        if (badgeA == null || badgeB == null) return 0;
        if (badgeA.sortScore === badgeB.sortScore) {
          return badgeB.rarityScore - badgeA.rarityScore;
        }
        return badgeA.sortScore - badgeB.sortScore;
      }),
    [badgeIDs, badgesMap]
  );

  const IDSincludingLondon = useMemo(() => {
    // So any id where the description has the word "London" in it
    return Array.from(badgesMap.keys()).filter((badgeID) => {
      const description = badgesMap.get(badgeID)?.description;
      if (description == null) return false;
      return description.includes('London');
    });
  }, [badgesMap]);

  const IDSincludingNewYork = useMemo(() => {
    // So any id where the description has the word "New York" or "NYC" in it
    return Array.from(badgesMap.keys()).filter((badgeID) => {
      const description = badgesMap.get(badgeID)?.description;
      if (description == null) return false;
      return description.includes('New York') || description.includes('NYC');
    });
  }, [badgesMap]);

  const profileHasALondonBadge = useMemo(() => {
    return selectedProfile?.badgesIDs.some((badgeID) => IDSincludingLondon.includes(badgeID));
  }, [IDSincludingLondon, selectedProfile?.badgesIDs]);

  const profileHasANewYorkBadge = useMemo(() => {
    return selectedProfile?.badgesIDs.some((badgeID) => IDSincludingNewYork.includes(badgeID));
  }, [IDSincludingNewYork, selectedProfile?.badgesIDs]);

  const BANNED_WORDS = useMemo(() => {
    const list = ['Visited', 'Accumulated', 'Hackathon'];
    // Only include London in this list if the profile doesn't have any other London badge
    if (!profileHasALondonBadge) {
      list.push('London');
    }
    // Only include New York and NYC in this list if the profile doesn't have any other New York badge
    if (!profileHasANewYorkBadge) {
      list.push('New York');
      list.push('NYC');
    }
    return list;
  }, [profileHasALondonBadge, profileHasANewYorkBadge]);

  const isConsideredMobile = window.innerWidth < 615;
  const [selectedFilters, setSelectedFilters] = useState({
    common: true,
    rare: true,
    legendary: true,
    search: '',
  });

  const missingBadgeIDS = useMemo(
    () =>
      Array.from(badgesMap.keys())
        .filter((badgeID) => {
          const description = badgesMap.get(badgeID)?.description;
          if (description == null) return false;
          if (selectedFilters.search === '') {
            // Only if we aren't using the search function ban the words
            if (BANNED_WORDS.some((word) => description.includes(word))) {
              return false;
            }
          }

          return !sortedBadgeIDS.includes(badgeID);
        })
        .sort((a, b) => {
          const badgeA = badgesMap.get(a);
          const badgeB = badgesMap.get(b);
          if (badgeA == null || badgeB == null) return 0;
          if (badgeA.sortScore === badgeB.sortScore) {
            return badgeA.rarityScore - badgeB.rarityScore;
          }
          return badgeB.sortScore - badgeA.sortScore;
        }),
    [BANNED_WORDS, badgesMap, selectedFilters.search, sortedBadgeIDS]
  );

  const hasFilters =
    !selectedFilters.common || !selectedFilters.rare || !selectedFilters.legendary || selectedFilters.search !== '';

  const nextBadgesToCollect = useMemo(() => {
    return missingBadgeIDS
      .filter((badgeID) => {
        const badge = badgesMap.get(badgeID);
        if (badge == null) {
          return null;
        }
        if (!`${badge.name}${badge.description}`.toLowerCase().includes(selectedFilters.search.toLowerCase())) {
          return false;
        }
        if (badge.rarityName === 'common' && !selectedFilters.common) {
          return false;
        }
        if (badge.rarityName === 'rare' && !selectedFilters.rare) {
          return false;
        }
        if (badge.rarityName === 'legendary' && !selectedFilters.legendary) {
          return false;
        }

        return true;
      })
      .slice(0, 5);
  }, [badgesMap, missingBadgeIDS, selectedFilters]);

  return (
    <div className={`collected-badges-container ${isConsideredMobile && 'collected-badges-container-mobile'}`}>
      {allowFilters && !isConsideredMobile && (
        <div className="filter-buttons-container">
          {/* <button
            className={`filter-button ${selectedFilters.legendary && 'selected glow-effect-legendary'}`}
            onClick={() => setSelectedFilters((prev) => ({ ...prev, legendary: !prev.legendary }))}
          >
            <img src={legendaryURL} alt="Legendary" style={{ height: '1rem', width: '1rem' }} />
          </button>
          <button
            className={`filter-button ${selectedFilters.rare && 'selected glow-effect-rare'}`}
            onClick={() => setSelectedFilters((prev) => ({ ...prev, rare: !prev.rare }))}
          >
            <img src={rareURL} alt="Rare" style={{ height: '1rem', width: '1rem' }} />
          </button>
          <button
            className={`filter-button ${selectedFilters.common && 'selected glow-effect-common'}`}
            onClick={() => setSelectedFilters((prev) => ({ ...prev, common: !prev.common }))}
          >
            <img src={commonURL} alt="Common" style={{ height: '1rem', width: '1rem' }} />
          </button>
          | */}
          <input
            className="search-box"
            placeholder="search badge..."
            value={selectedFilters.search}
            onChange={(e) => {
              setSelectedFilters((prev) => ({ ...prev, search: e.target.value }));
            }}
          />
        </div>
      )}
      <div className={`collected-badges-inner-container ${selectedProfile == null && 'expanded'}`}>
        {sortedBadgeIDS.map((badgeID) => {
          const badge = badgesMap.get(badgeID);
          let excludeDueToFilter = false;
          if (badge == null) {
            return null;
          }
          if (!`${badge.name}${badge.description}`.toLowerCase().includes(selectedFilters.search.toLowerCase())) {
            excludeDueToFilter = true;
          }
          if (badge.rarityName === 'common' && !selectedFilters.common) {
            excludeDueToFilter = true;
          }
          if (badge.rarityName === 'rare' && !selectedFilters.rare) {
            excludeDueToFilter = true;
          }
          if (badge.rarityName === 'legendary' && !selectedFilters.legendary) {
            excludeDueToFilter = true;
          }
          if (excludeDueToFilter && clearResultsOnFilter) {
            return null;
          }
          return (
            <BadgeComponent
              key={badgeID}
              badge={badge}
              onClick={() => onClickBadge(badge)}
              display={!excludeDueToFilter}
              showName={true}
              showDescription={true}
              smaller
              blackAndWhite={
                excludeDueToFilter ||
                (selectedProfile != null && !viewNextBadges && !selectedProfile.badgesIDs.includes(badgeID))
              }
            />
          );
        })}
      </div>
      <center className="next-badges">
        {viewNextBadges && nextBadgesToCollect.length > 0 && (
          <span>
            <p className="title">
              Next {hasFilters ? <i>filtered</i> : ''} badges <i>{selectedProfile?.name}</i> could collect:
            </p>
            <div className={`collected-badges-inner-container ${selectedProfile == null && 'expanded'}`}>
              {nextBadgesToCollect.map((badgeID) => {
                const badge = badgesMap.get(badgeID)!;
                return (
                  <BadgeComponent
                    key={badgeID}
                    badge={badge}
                    onClick={() => onClickBadge(badge)}
                    display={true}
                    showName={true}
                    blackAndWhite={true}
                    smaller
                  />
                );
              })}
            </div>
          </span>
        )}
      </center>
    </div>
  );
};
