import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './App.css';

import {
  type Badge,
  useBadgeWithoutSortScore,
  useBadges,
  useProfiles,
  useProfilesWithoutSortScore,
} from './hooks/useTalosBadges.tsx';

import { MainButtonsContainer } from './containers/MainButtonsContainer.tsx';
import { BadgesBackgroundScrollContainer } from './containers/BadgesBackgroundScrollContainer.tsx';
import { useLocation, useNavigate } from 'react-router-dom';

export const App = () => {
  const { badgeWithoutSortScoreMap } = useBadgeWithoutSortScore();
  const { profilesWithoutSortScoreMap } = useProfilesWithoutSortScore(badgeWithoutSortScoreMap);
  const { profilesMap } = useProfiles(badgeWithoutSortScoreMap, profilesWithoutSortScoreMap);
  const { badgesMap } = useBadges(profilesMap, badgeWithoutSortScoreMap);
  const [selectedProfileID, setSelectedProfileID] = useState<string | null>(null);
  const [selectedBadge, setSelectedBadge] = useState<Badge | null>(null);
  const [viewBadgeGallery, setViewBadgeGallery] = useState(false);
  const [ghostProfileID, setGhostProfileID] = useState<string | null>(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  // Redirect from the old url talosbadges.netlify.app to new url talosbadges.com
  useEffect(() => {
    if (window.location.href.includes('talosbadges.netlify.app')) {
      window.location.href = `https://talosbadges.com${window.location.pathname}`;
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    const name = location.pathname.split('/')[1];
    if (name) {
      const email = `${name}@talostrading.com`;
      if (profilesMap.has(email)) {
        setSelectedProfileID(email);
        setGhostProfileID(email);
      }
    } else {
      setSelectedProfileID(null);
      setGhostProfileID(null);
    }
  }, [location, profilesMap]);

  const shouldShowLoading = useMemo(() => {
    return (
      badgeWithoutSortScoreMap.size === 0 ||
      badgesMap.size === 0 ||
      profilesWithoutSortScoreMap.size === 0 ||
      profilesMap.size === 0
    );
  }, [badgeWithoutSortScoreMap.size, badgesMap.size, profilesMap.size, profilesWithoutSortScoreMap.size]);

  const navigate = useNavigate();

  const handleSetProfileID = useCallback(
    (profileID: string | null) => {
      setSelectedProfileID(profileID);
      setGhostProfileID(profileID);
      const emailBeforeAt = profileID?.split('@')[0];
      if (profileID == null) {
        navigate('/');
      } else {
        navigate(`/${emailBeforeAt}`);
      }
    },
    [navigate]
  );

  const [mode, setMode] = useState<'collected' | 'submitted' | 'journeys'>('collected');

  const handleSetMode = useCallback(
    (mode: 'collected' | 'submitted' | 'journeys') => {
      setMode(mode);
    },
    [setMode]
  );

  if (shouldShowLoading) {
    return (
      <center className="loading">
        {badgesMap.size === 0 ? <p>Loading Badges</p> : profilesMap.size === 0 ? <p>Loading Profiles</p> : null}
        <img src="favicon.ico" alt="loading" className="spinning" width="20rem" height="20rem" />
      </center>
    );
  }

  if (window.innerWidth < 350) {
    return <center className="loading">Screen width too small.</center>;
  }

  return (
    <div className="App">
      <center className={`loading ${imagesLoaded && 'done'}`}>
        <p>Loading Images</p>
        <img src="favicon.ico" alt="loading" className="spinning" width="20rem" height="20rem" />
      </center>
      <div className={!imagesLoaded ? 'hide' : ''}>
        <BadgesBackgroundScrollContainer
          selectedBadge={selectedBadge}
          setSelectedBadge={setSelectedBadge}
          badgesMap={badgesMap}
          profilesMap={profilesMap}
          ghostProfileID={ghostProfileID}
          setSelectedProfileID={handleSetProfileID}
          enableInteraction={selectedProfileID == null && viewBadgeGallery === false}
          setMode={handleSetMode}
          selectedProfileID={selectedProfileID}
        />
        <MainButtonsContainer
          profilesMap={profilesMap}
          badgesMap={badgesMap}
          selectedProfileID={selectedProfileID}
          setSelectedProfileID={handleSetProfileID}
          selectedBadge={selectedBadge}
          setSelectedBadge={setSelectedBadge}
          viewBadgeGallery={viewBadgeGallery}
          setViewBadgeGallery={setViewBadgeGallery}
          setGhostProfileID={setGhostProfileID}
          setImagesLoaded={setImagesLoaded}
          mode={mode}
          setMode={handleSetMode}
        />
      </div>
    </div>
  );
};
