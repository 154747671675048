import React from 'react';
import { Badge } from '../hooks/useTalosBadges';
import { BadgeComponent } from './BadgeComponent.tsx';
import './JourneyComponent.css';

interface PathStep {
  badgeOrs: string[];
  badgeAnds: string[];
}
interface Journey {
  name: string;
  path: PathStep[];
  id: string;
  rarityName: string;
  rarityImageUrl: string;
}

export const exampleJourneys: Journey[] = [
  {
    name: `Culture Quest`,
    id: `culture-quest`,
    rarityName: 'rare',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/rare.png`,
    // Explorer and Retreat Ranger --> Holiday Hero or Portrait Pioneer --> Jet Boots or Culture Catalyst
    path: [
      {
        badgeOrs: [],
        badgeAnds: ['21yr', '1tgz'],
      },
      {
        badgeOrs: ['tnlq', '9x0m'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['hpai', 'tk16'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Engineer's Dynamics`,
    id: `engineer-dynamics`,
    rarityName: 'common',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/common.png`,
    // The Frank or Red Stapler --> Unscripted Hero or Stretch Squad --> Gitcraft Wizard or On-Call Oracle
    path: [
      {
        badgeOrs: ['nmyu', 'iqzd'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['hoeg', 'jjqu'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['p12z', 'jdoi'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Host of Fun`,
    id: `host-of-fun`,
    rarityName: 'common',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/common.png`,
    //  Event Enthusiast --> Champion OR Lasagna --> Poker Shark OR Buzz Builder
    path: [
      {
        badgeOrs: ['nnm0'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['jt0d', 'hl4y'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['rt3p', 'htci'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Growth Gardener`,
    id: `growth-gardener`,
    rarityName: 'common',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/common.png`,
    // Foundational Forest --> Junior Jedi or Yeah Buddy --> Volunteer or Knowledge Sharer or Culture Catalyst
    path: [
      {
        badgeAnds: ['tpbo'],
        badgeOrs: [],
      },
      {
        badgeAnds: [],
        badgeOrs: ['q6ss', 'ixey'],
      },
      {
        badgeAnds: [],
        badgeOrs: ['fyzv', '1ze3', 'tk16'],
      },
    ],
  },
  {
    name: `Creative Spark`,
    id: `creative-spark`,
    rarityName: 'rare',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/rare.png`,
    // Hackathon Participant or Crypto Conqueror --> Hidden Talent or Tunesmith or Badger --> Beef Wellington Bandit or Crypto Chef or PBGY
    path: [
      {
        badgeOrs: ['dr56', 'krih'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['iuvn', 'y0az', '65ry'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['wg99', '3mjs', 'gu53'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Crowd Favorite`,
    id: `crowd-favorite`,
    rarityName: 'legendary',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/legendary.png`,
    //  Unscripted Hero or Presenter Prodigy --> SRT Distributoor and All for One and Mirror Image --> Pet Parade Participant and Cheer Leader and Buzz Builder
    path: [
      {
        badgeAnds: [],
        badgeOrs: ['hoeg', 'n26f'],
      },
      {
        badgeAnds: ['x7tl', 'snp0', 'edgc'],
        badgeOrs: [],
      },
      {
        badgeAnds: ['52nh', '7ays', 'htci'],
        badgeOrs: [],
      },
    ],
  },
  {
    name: `Talos Influencer`,
    id: `talos-influencer`,
    rarityName: 'legendary',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/legendary.png`,
    // Ragster Riches  --> Referral Rookie or Deal Conqueror   --> Galactic Speaker or The Road Runner or Tech Trailblazer
    path: [
      {
        badgeOrs: [],
        badgeAnds: ['gcr2'],
      },
      {
        badgeOrs: ['0lkm', '6sj5'],
        badgeAnds: [],
      },

      {
        badgeOrs: ['0gfr', 'ao8z', 'mh96'],
        badgeAnds: [],
      },
    ],
  },
];

export const useIsJourneyCompleted = (journey: Journey, badgeIDs: string[]) => {
  return journey.path.every(
    (pathStep) =>
      pathStep.badgeAnds.every((badgeID) => badgeIDs.includes(badgeID)) &&
      (pathStep.badgeOrs.length === 0 || pathStep.badgeOrs.some((badgeID) => badgeIDs.includes(badgeID)))
  );
};

export const JourneyComponent = ({
  journey,
  badgesMap,
  badgeIDs,
  onClickBadge,
}: {
  journey: Journey;
  badgesMap: Map<string, Badge>;
  badgeIDs: string[];
  onClickBadge: (badge: Badge) => void;
}) => {
  const isJourneyCompleted = useIsJourneyCompleted(journey, badgeIDs);

  return (
    <div className={`journey ${isJourneyCompleted ? `completed` : ''} ${journey.id}`}>
      <div className="journey-badges">
        <img
          className={`journey-badge-rarity glow-effect-${journey.rarityName}`}
          src={journey.rarityImageUrl}
          alt={journey.rarityName}
        />
        <h3 className="journey-name">{journey.name}</h3>
        {journey.path.map((pathStep, index) => (
          <div className="journey-badges-step" key={index}>
            {pathStep.badgeAnds.map((badgeID, index) => (
              <div className="journey-badges-step-inner" key={index}>
                {index > 0 && <span className="badge-connector">AND</span>}
                <BadgeComponent
                  key={badgeID}
                  badge={badgesMap.get(badgeID)!}
                  onClick={onClickBadge}
                  smaller
                  display
                  blackAndWhite={!badgeIDs.includes(badgeID)}
                />
              </div>
            ))}
            {pathStep.badgeAnds.length > 0 && pathStep.badgeOrs.length > 0 && (
              <span className="badge-connector">AND</span>
            )}
            {pathStep.badgeOrs.map((badgeID, index) => (
              <div className="journey-badges-step" key={index}>
                {index > 0 && <span className="badge-connector">OR</span>}
                <BadgeComponent
                  key={badgeID}
                  badge={badgesMap.get(badgeID)!}
                  onClick={onClickBadge}
                  smaller
                  display
                  blackAndWhite={!badgeIDs.includes(badgeID)}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
